<template>

  <el-container style="height:calc(100vh);">
    <el-container style="width:75%; height:calc(100vh);">
      <el-header class="header">
        <div class="top">
          <span class="left el-icon-arrow-left" @click="returnOn"></span>
          <span>服务管理</span>
          <router-link to="add-service" class="right">新增服务</router-link>
        </div>
        <div class="search">
          <el-input clearable placeholder="输入服务名称或编号" prefix-icon="el-icon-search" v-model="searchVal"
            @keyup.enter.native="searchChange">
          </el-input>
          <el-button type="primary" @click="searchChange">搜索</el-button>
        </div>
      </el-header>
      <el-container>
        <el-aside class="aside" width="120px">
          <el-menu class="menu">
            <el-menu-item v-for=" (item,i) in severLlist" :key="i">
              <template slot="title">
                <div :class="activeItem===i?'nav1':'nav'" @click="activeItem=i">
                  <span>{{item.name}}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container style="height:calc(93.5vh);">
          <el-main class="main">
            <el-row :gutter="15" style="padding:10px 10px 60px 0;" v-if="severLlist[activeItem]">
              <el-col class="cursor" :span="rowCol" v-for="(item ,i) in severLlist[activeItem].server" :key="i">
                <div :class="itemserver.id == item.id?'order-card order-card1':'order-card'"
                  @click="activeList(item)">
                  <div class="card-name">{{ item.name }}</div>
                  <div class="btm">
                    <div class="btm-time">{{ item.hour }}</div>
                    <div class="btm-price">
                      <del>¥{{item.original_price}}</del>
                      <strong>¥{{ item.vip_price }}</strong>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-main>
        </el-container>
      </el-container>
    </el-container>

    <el-container style="width:25%;min-width:300px; border-left:1px solid #f2f2f2" v-show="serverInfo.name">
      <el-main class="right-main">
        <div class="content">
          <div class="about-info">
            <div class="info-title">
              服务详情
            </div>
            <div class="list">
              <div class="left">服务名称</div>
              <div class="right">{{ serverInfo.name }}</div>
            </div>
            <div class="list">
              <div class="left">服务分类</div>
              <div class="right">{{  serverInfo.servercate_id}}</div>
            </div>
            <div class="list">
              <div class="left">服务时长</div>
              <div class="right">{{ serverInfo.hour }}</div>
            </div>
            <div class="list">
              <div class="left">单价</div>
              <div class="right">¥{{  serverInfo.original_price}}</div>
            </div>
            <div class="list">
              <div class="left">会员价</div>
              <div class="right">¥{{ serverInfo.vip_price }}</div>
            </div>
            <div class="list">
              <div class="left">服务介绍</div>
              <div class="right">{{ serverInfo.introduce }}</div>
            </div>
            <div class="list">
              <div class="left">是否热门</div>
              <div class="right">{{ serverInfo.stat }}</div>
            </div>
            <div class="list">
              <div class="left">是否增送积分</div>
              <div class="right">{{ serverInfo.zscon }}</div>
            </div>
            <div class="list">
              <div class="left">赠送分值</div>
              <div class="right">{{  serverInfo.zsinteg}}</div>
            </div>
            <div class="list">
              <div class="left">提成比例</div>
              <div class="right">{{ serverInfo.draw }}</div>
            </div>
            <div class="list">
              <div class="left">提成</div>
              <div class="right">{{  serverInfo.drawnum}}</div>
            </div>

          </div>
        </div>

        <el-dialog title="设置打印数量" :visible.sync="pointShow">
          <el-input v-model="pointNum" placeholder="输入打印数量"></el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="pointShow = false">取 消</el-button>
            <el-button type="primary" @click="printLabel">确 定</el-button>
          </div>
        </el-dialog>
      </el-main>
      <el-footer>
        <div class="foot">
          <div class="right el-icon-edit" @click="changeDetail">修改信息</div>
        </div>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        item: ['常用服务', '基础护理', '面部护理', '养生套餐', '美容套餐', '烫发染发'],
        activeItem: 0,
        activeItemList: [],
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        // 预约信息
        searchVal: '',
        // 打印
        pointShow: false,
        pointNum: '',
        //服务列表
        severLlist:[],
        //选中的服务
        itemserver:{},
        //服务详情
        serverInfo:{}
      }
    },

    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
        } else if (val >= 1180) {
          this.rowCol = 8
        } else if (val >= 900) {
          this.rowCol = 12
        } else if (val <= 900) {
          this.rowCol = 24
        }
      }
    },

    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        that.rowCol = 6
      } else if (that.innerWidth >= 1180) {
        that.rowCol = 8
      } else if (that.innerWidth >= 900) {
        that.rowCol = 12
      } else if (that.innerWidth <= 900) {
        that.rowCol = 24
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
      this.gerServerList()
    },
    methods: {
      //获取服务详情
      getServerInfo(){
        this.$http.post('api/store/server/serverdetail',{
          sid:this.itemserver.id
        }).then(res =>{
          this.serverInfo = res.data.data
        })
      },
      //服务管理列表
      gerServerList(){
        this.$http.post('api/store/server/serverlist').then(res =>{
          this.severLlist = res.data.data
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 搜索
      searchChange() {
        if (this.searchVal.trim()) {
          console.log(this.searchVal.trim());
        } else {
          this.$message.error('搜索内容不能为空')
        }
      },
      // 选中服务
      activeList(item) {
        this.itemserver = item
        this.getServerInfo()
        // console.log(this.activeItemList.indexOf(item));
        // if (this.activeItemList.indexOf(item) === -1) {
        //   this.activeItemList.push(item)
        // } else {
        //   this.activeItemList.splice(this.activeItemList.indexOf(item), 1)
        // }
      },
      // 打印标签
      printLabel() {
        this.$message('正在打印请稍后...')
        this.pointShow = false
        setTimeout(() => {
          this.$message({
            message: '打印成功',
            type: 'success'
          })
          this.pointNum = ''
        }, 3000);
      },
      // 修改服务
      changeDetail() {
        this.$router.push({
          path: '/edit-service',
          query: {
            info:JSON.stringify(this.serverInfo),
            type: 'edit'
          }
        })
      }
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  /deep/ .header.el-header {
    position: relative;
    height: 100px !important;
    border-bottom: 1px solid #e9e9e9;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px;
      line-height: 50px;
      text-align: center;

      .left {
        color: #46a6ff;
        font-size: 24px;
      }

      .right {
        font-weight: normal;
        font-size: 12px;
        color: #409eff;
      }
    }

    .search {
      position: relative;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .el-button--primary {
        height: 40px;
        margin-left: -5px;
        z-index: 1;
        font-size: 12px;
        border-radius: 0 5px 5px 0;
        border: 1px solid #3d96f2;
      }

      .el-button--primary:focus,
      .el-button--primary:hover,
      .el-button:active,
      .el-button:focus,
      .el-button:hover {
        color: #fff;
        border-color: #409eff;
        background-color: #409eff;
      }

      /deep/ .el-input__inner {
        height: 35px;
        border: 1px solid #dcdfe6;
        border-right: 0;
        border-radius: 5px 0 0 5px;

        &:focus,
        &:hover {
          border-color: #dcdfe6;
        }
      }
    }
  }

  // 左侧导航
  .aside {
    min-height: calc(93.5vh);
    background-color: #f9f9f9;

    &:first-child {
      padding-top: 30px;
    }

    .nav {
      text-align: center;

      span {
        border: 1px solid transparent;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }

    .nav1 {
      text-align: center;

      span {
        color: #409eff;
        border: 1px solid #409eff;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }
  }

  // 左侧服务卡片
  .main {
    position: relative;
    z-index: 9;
    background-color: #f9f9f9;

    .order-card {
      box-shadow: 0px 0px 4px #ccc;
      margin: 8px 0;
      padding: 15px;
      border-radius: 10px;
      background-color: #f4f4f4;
      overflow: hidden;
      border: 1px solid #f4f4f4;

      .card-name {
        font-size: 14px;
        color: #666;
        margin-bottom: 30px;
      }

      .btm {
        color: #999;
        font-size: 12px;

        .btm-time {
          float: left;
        }

        .btm-price {
          float: right;

          strong {
            margin-left: 10px;
            font-size: 14px;
            color: #f56c6c;
          }
        }
      }
    }

    .order-card1 {
      border: 1px solid #409eff;
      background-color: #e6eff9;
      box-shadow: none;
    }
  }

  // 右侧详情
  /deep/ .right-main.el-main {
    background-color: #fff;

    .content {
      box-sizing: border-box;
      padding: 10px;

      .about-info {
        .info-title {
          font-size: 14px;
          line-height: 40px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #666;
        }

        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;
          font-size: 12px;
          border-bottom: 1px solid #f2f2f2;

          &:last-child {
            border-bottom: none;
          }

          .left {
            color: #999;
          }

        }

      }

    }
  }

  .dialog {
    /deep/.el-dialog__body {
      height: 500px;

    }

    .alert {
      color: #ff9900;
      margin-bottom: 20px;
    }

    .cell-list {
      width: 100%;

      /deep/ .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #f2f2f2;
          padding: 10px;
          margin-bottom: 10px;
          margin-right: 0;

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }

  .foot {
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #f2f2f2;

    .right {
      width: 100%;
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
      text-align: center;
      line-height: 40px;
      border-radius: 40px;
    }
  }

  /deep/ .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;

  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #f9f9f9;

  }

  .main.el-main {
    padding: 0 10px !important;
  }

</style>
